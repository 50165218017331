@import "../../../node_modules/animate.css/source/animate.css";

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, #root, #map-container {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

.MuiInputLabel-shrink.shrink-label-hide {
    display: none;
}
